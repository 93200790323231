import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'q3-ui-locale';
import Typography from '@material-ui/core/Typography';
import Wrapper from 'q3-ui/src/wrapper';
import { SplitPanel } from 'q3-ui/src/panel';

const TransactionLayout = ({ imgSrc, title, children }) => {
  const { t } = useTranslation();

  return (
    <Box component="main" my={4}>
      <Wrapper backgroundColor="#FFF">
        <SplitPanel
          align="center"
          columnRight={
            <img
              src={imgSrc}
              alt={t(`labels:${title}`)}
              style={{
                maxHeight: 350,
                display: 'block',
                margin: '0 auto',
              }}
            />
          }
          columnLeft={
            <Box p={2}>
              <Typography variant="h1" gutterBottom>
                {t(`titles:${title}`)}
              </Typography>

              {children}
            </Box>
          }
        />
      </Wrapper>
    </Box>
  );
};

TransactionLayout.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default TransactionLayout;
