import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'q3-ui-locale';
import TransactionLayout from '../components/TransactionLayout';
import ConfirmationSvg from '../assets/confirmation.svg';

const ContactThanks = () => {
  const { t } = useTranslation();

  return (
    <TransactionLayout
      imgSrc={ConfirmationSvg}
      title="contactThanks"
    >
      <Typography variant="body2" gutterBottom>
        {t('descriptions:contactThanks')}
      </Typography>
    </TransactionLayout>
  );
};

export default ContactThanks;
